<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ $t('module.perujuk') }}</h5>
            </div>
            <div class="modal-body">
                <div class="row mb-2">
                    <label class="col-lg-3 kt-font-bold">{{ $t('common.firstName') }}:</label>
                    <div class="col-lg-6">
                       {{ perujuk.firstName }}
                    </div>
                </div>
                <div class="row mb-2">
                    <label class="col-lg-3 kt-font-bold">{{ $t('common.lastName') }}:</label>
                    <div class="col-lg-6">
                        {{ perujuk.lastName }}
                    </div>
                </div>
                <div class="row mb-2">
                    <label class="col-lg-3 kt-font-bold">{{ $t('common.kategori') }}:</label>
                    <div class="col-lg-6">
                        {{ perujuk.kategoriNama }}
                    </div>
                </div>
                <div class="row mb-2">
                    <label class="col-lg-3 kt-font-bold">{{ $t('common.email') }}:</label>
                    <div class="col-lg-6">
                        {{ perujuk.email }}
                    </div>
                </div>
                <div class="row mb-2">
                    <label class="col-lg-3 kt-font-bold">{{ $t('common.phoneNo') }}:</label>
                    <div class="col-lg-8">
                        <div class="row mb-2" v-for="value in perujuk.teleponPerujuks" :key="value.id">
                            <div class="col-4">{{ $t('EnumStage.'+value.tipe) }}</div>
                            <div class="col-6">{{ value.nomor | filterPhone }}</div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <label class="col-lg-3 kt-font-bold">{{ $t('common.alamat') }}:</label>
                    <div class="col-lg-8">
                        <div class="row mb-2" v-for="value in perujuk.alamatPerujuks" :key="value.id">
                            <div class="col-4">{{ $t('EnumStage.'+value.tipe) }}</div>
                            <div class="col-4">{{ value.nama }}</div>
                            <div class="col-4">{{ value.jalan }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-clean btn-bold btn-upper btn-font-md" @click="clearData()" >{{ $t('button.close') }}</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
import Perujuk from '../../../model/perujuk-model';
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
  components: {

  },
  data() {
    return {
        perujuk: new Perujuk()
    }
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object },
  },
  filters: {
        filterPhone: function(value) {
          return helpers.formatPhoneNumber(value);
        },
  },
  methods: {
      clearData: function() {
        $("#" + this.modalName).modal("hide");
        this.$emit("update:showModal", false);
      }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.perujuk = this.modalData;
    }
  }
};
</script>

<style>
</style>
